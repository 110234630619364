import React, { Component, Fragment, useState, useEffect, } from 'react';
import { useToggle, useAsync, useMap, } from 'react-use';
import { isEmpty, get, orderBy, sumBy, groupBy, keyBy, sortBy, omit, } from 'lodash';
import { Button } from 'reactstrap';
import Select from 'react-select';
import qs from 'qs';
import { toast } from 'react-toastify';
import classnames from 'classnames';

import { batch } from '../../shared/firebase';
import firebase, { functions } from '../../firebase';
import { accountItemCategoryNames, } from '../../shared/config';
import { numberFormat, floatFormat, } from '../../util';
import { withConsolidationAccountItem, } from '../../shared/models/trial';
import { getCategory, } from '../../shared/models/accountItem';
import { changeFieldNames as investmentChangeFieldNames, fields, } from '../../shared/models/investment';
import { fields as investmentRelatedItemFields } from '../../shared/models/investmentRelatedItem';
import { changeFieldNames as fixedDepositChangeFieldNames, fields as fixedDepositFields, } from '../../shared/models/fixedDeposit';
import { changeFieldNames as loanChangeFieldNames, fields as loanFields, } from '../../shared/models/loan';
import { fields as incomeInterestItemFields } from '../../shared/models/incomeInterestItem';
import { changeFieldNames as otherLiabilityChangeFieldNames, fields as otherLiabilityFields } from '../../shared/models/otherLiability';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCompanySelector from '../hooks/useCompanySelector';
import useQueryParams from '../hooks/useQueryParams';
import RelatedCompanyPage from '../hocs/RelatedCompanyPage';
import ModelFormModal from '../modals/ModelFormModal';
import CompanySyncButton from '../CompanySyncButton';
import TrialsSyncButton from '../TrialsSyncButton';
import ProgressButton from '../ProgressButton';
import AutoLinkText from '../AutoLinkText';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import QuerySelector from '../QuerySelector';
import AmountWithExchange from '../AmountWithExchange';
import IndividualChangesTableSection from '../IndividualChangesTableSection';
import IndividualSimpleTableSection from '../IndividualSimpleTableSection';

const { keys, entries } = Object;
const db = firebase.firestore();

export default RelatedCompanyPage(function CompanyInvestments (props) {
  const { period, relatedCompany, yearMonth, prevEndYearMonth, company, subsidiaryId, isLockedMonth, startYearMonth, } = props;
  const [state, { set: setState }] = useMap({});
  const accountItems = useCollectionSubscription(company.ref.collection('accountItems').where('subsidiaryId', '==', subsidiaryId), [company, subsidiaryId]);
  const itemTrials = useCollectionSubscription(company.ref.collection('itemTrials').where('subsidiaryId', '==', subsidiaryId).where('yearMonth', '==', yearMonth), [subsidiaryId, yearMonth]);
  const trials = useCollectionSubscription(company.ref.collection('trials').where('subsidiaryId', '==', subsidiaryId).where('yearMonth', '==', yearMonth), [subsidiaryId, yearMonth]);
  const startYearMonthTrials = useCollectionSubscription(company.ref.collection('trials').where('subsidiaryId', '==', subsidiaryId).where('yearMonth', '==', startYearMonth), [subsidiaryId, startYearMonth]);
  const consolidationAccountItems = useCollectionSubscription(company.ref.collection('accountItems').where('subsidiaryId', '==', null), [company]);

  return props.translate(
    <div className="company-investments">
      <IndividualChangesTableSection
        section="投資関連"
        title="投資その他増減"
        filterTrials={_ => (
          (_.account_item_name && _.account_category_name === '投資その他の資産' && !['自己株式', '長期固定性預金'].includes(_.consolidationAccountItem?.group_name) && !_.consolidationAccountItem?.group_name?.match(/貸付金|引当金/))
          || (_.account_item_name?.includes('有価証券'))
        )}
        collectionName="investments"
        fields={fields}
        changeFieldNames={investmentChangeFieldNames}
        onLoadItems={_ => setState('投資その他増減', _)}
        {...{ ...props, accountItems, consolidationAccountItems, itemTrials, trials, startYearMonthTrials, }}
      />
      <hr className="my-5" />
      <IndividualSimpleTableSection
        section="投資関連"
        title="取得または売却にかかる債権債務・売却損益"
        collectionName="investmentRelatedItems"
        fields={investmentRelatedItemFields}
        {...{ ...props, accountItems, trials, startYearMonthTrials, }}
      />
      <hr className="my-5" />
      <IndividualChangesTableSection
        section="投資関連"
        title="定期預金"
        filterTrials={_ => (
          (_.account_item_name && _.account_category_name === '投資その他の資産' && ['長期固定性預金'].includes(_.consolidationAccountItem?.group_name))
          || (_.account_item_name?.includes('定期預金'))
        )}
        collectionName="fixedDeposits"
        fields={fixedDepositFields}
        changeFieldNames={fixedDepositChangeFieldNames}
        onLoadItems={_ => setState('定期預金', _)}
        {...{ ...props, accountItems, consolidationAccountItems, itemTrials, trials, startYearMonthTrials, }}
      />
      <hr className="my-5" />
      <IndividualChangesTableSection
        section="投資関連"
        title="外部貸付"
        filterTrials={_ => _.consolidationAccountItem?.group_name?.match(/貸付金/)}
        collectionName="loans"
        fields={loanFields}
        changeFieldNames={loanChangeFieldNames}
        onLoadItems={_ => setState('外部貸付', _)}
        {...{ ...props, accountItems, consolidationAccountItems, itemTrials, trials, startYearMonthTrials, }}
      />
      <hr className="my-5" />
      <IndividualSimpleTableSection
        section="投資関連"
        title="未収利息・前受利息"
        collectionName="incomeInterestItems"
        fields={incomeInterestItemFields}
        {...{ ...props, accountItems, trials, startYearMonthTrials, }}
      />
      <hr className="my-5" />
      <IndividualChangesTableSection
        section="投資関連"
        title="その他負債"
        filterTrials={_ => (
          (_.account_category_name === '他流動負債' && _.consolidationAccountItem?.group_name?.match(/リース債務|リース負債/))
          || (_.account_category_name === '固定負債' && ['借入金', '社債', '退職給付引当金', '繰延税金負債(固)', '引当金'].every(s => !_.consolidationAccountItem?.group_name?.includes(s)))
        )}
        collectionName="otherLiabilities"
        fields={otherLiabilityFields}
        changeFieldNames={otherLiabilityChangeFieldNames}
        onLoadItems={_ => setState('その他負債', _)}
        {...{ ...props, accountItems, consolidationAccountItems, itemTrials, trials, startYearMonthTrials, }}
      />
    </div>
  );
});
