import React, { useMemo, Fragment, Component, useRef, useEffect, } from 'react';
import { useToggle, useAsync, useRaf, useList, } from 'react-use';
import 'lodash.multicombinations';
import { pickBy, difference, round, pick, isEqual, multicombinations, orderBy, uniq, get, invert, mapValues, isEmpty, last, sumBy, groupBy, keyBy, sortBy, omit, } from 'lodash';
import { Input, Button } from 'reactstrap';
import Select from 'react-select';
import qs from 'qs';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import { Container, Draggable } from 'react-smooth-dnd';
import { arrayMoveImmutable } from 'array-move';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import { useHistory, useLocation, } from 'react-router';
import dedent from 'dedent';
import { format as formatDate, addSeconds, } from 'date-fns';
import { Tooltip } from 'react-tooltip'

import { accountItemCategoryNames } from '../../shared/config';
import { fiscalYearOfPeriod, } from '../../utils';
import { changeTypes } from '../../shared/changeTypes';
import texts from '../../shared/texts';
import firebase, { functions } from '../../firebase';
import { batch, getCollectionData, } from '../../shared/firebase';
import { getCategory, } from '../../shared/models/accountItem';
import { generalSegment, } from '../../shared/models/consolidationSegment';
import { presetConsolidationJournalTypes, } from '../../shared/models/consolidationJournalType';
import { presetConsolidationAccountItems } from '../../shared/models/consolidationAccountItem';
import { fields, itemFields, inheritTypes, batchCloneFields, } from '../../shared/models/consolidationJournal';
import { generateRowGroups, rowsForExport, computeAlerts, } from '../../shared/lib/consolidationJournals';
import { integerFormat } from '../../util';
import useScrollFocusByQuery from '../hooks/useScrollFocusByQuery';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCompanySelector from '../hooks/useCompanySelector';
import useQueryParams from '../hooks/useQueryParams';
import CompanyPage from '../hocs/CompanyPage';
import HelpLink from '../HelpLink';
import ConsolidationJournalFormModal from '../modals/ConsolidationJournalFormModal';
import ModelFormModal from '../modals/ModelFormModal';
import ProgressButton from '../ProgressButton';
import CompanySyncButton from '../CompanySyncButton';
import TrialsSyncButton from '../TrialsSyncButton';
import AutoLinkText from '../AutoLinkText';
import ModalButton from '../ModalButton';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import ExportButton from '../ExportButton';
import ImportButton from '../ImportButton';
import QuerySelector from '../QuerySelector';
import Alerts from '../Alerts';

const { abs } = Math;
const { keys, entries, } = Object;
const db = firebase.firestore();
const inheritTypesByLabel = invert(mapValues(inheritTypes, _ => _.label));
const inheritTypeOptions = entries(inheritTypes).map(([k, v]) => ({ label: v.label, value: k, }));
const changeTypesByLabel = invert(mapValues(changeTypes, _ => _.label));
const openings = {
  isOpening: { label: '開始仕訳', value: true, },
  isNotOpening: { label: '開始仕訳以外', value: false, },
};
const openingOptions = entries(openings).map(([k, v]) => ({ label: v.label, value: k, }));
const approvements = {
  isApproved: { label: '承認済み', value: true, },
  isNotApproved: { label: '未承認', value: false, },
};
const approvementOptions = entries(approvements).map(([k, v]) => ({ label: v.label, value: k, }));
const intersegments = {
  intersegment: { label: 'セグメント間', value: true, },
  isNotIntersegment: { label: 'セグメント間以外', value: false, },
};
const intersegmentOptions = entries(intersegments).map(([k, v]) => ({ label: v.label, value: k, }));

export default CompanyPage(function CompanyConsolidationJournals (props) {
  const { user, company, period, yearMonth, prevEndYearMonth, periodOptions, isLockedMonth, } = props;
  const { role, } = company?.users?.[user.id] || {};
  const canApprove = user?.admin || ['owner', 'admin'].includes(role) || company?.users?.[user.id]?.approvable;
  const queryParams = useQueryParams();
  const {
    types: typesForFilter,
    consolidationAccountItemIds,
    consolidationSegmentIds,
    onlyFilteredRows,
  } = queryParams;
  const history = useHistory();
  const location = useLocation();
  const [isDragging, toggleDragging] = useToggle(false);
  const [selectedIds, { push: selectId, removeAt: removeSelectedIdAt, set: setSelectedIds, }] = useList([]);
  const unselectId = (_) => removeSelectedIdAt(selectedIds.indexOf(_));
  const isSelecting = selectedIds.length > 0;
  const subsidiaries = useCollectionSubscription(company.ref.collection('subsidiaries').orderBy('index'));
  const relatedCompanies = useMemo(_ => [company, ...subsidiaries], [company, subsidiaries]);
  const filteredRelatedCompanies = useMemo(_ => isEmpty(queryParams.relatedCompanyIds) ? relatedCompanies : relatedCompanies.filter(_ => queryParams.relatedCompanyIds.includes(_.id)), [relatedCompanies, queryParams.relatedCompanyIds]);
  const companiesByName = keyBy(relatedCompanies, 'display_name');
  const companiesById = keyBy(relatedCompanies, 'id');
  const consolidationJournalsRef = company.ref.collection('consolidationJournals');
  const { items: consolidationJournals, isLoading: isLoadingConsolidationJournals } = useCollectionSubscription(consolidationJournalsRef.where('yearMonth', '==', yearMonth), [company, yearMonth], { detail: true });
  const sortedConsolidationJournals = sortBy(consolidationJournals, 'index');
  const consolidationJournalsById = keyBy(consolidationJournals, 'id');
  const consolidationSegments = useCollectionSubscription(company.ref.collection('consolidationSegments'), [company]);
  const sortedConsolidationSegments = sortBy(consolidationSegments, 'index');
  const allConsolidationSegments = [...sortedConsolidationSegments, generalSegment];
  const consolidationSegmentsById = keyBy(allConsolidationSegments, 'id');
  const consolidationSegmentsByName = keyBy(allConsolidationSegments, 'name');
  const consolidationJournalTypes = useCollectionSubscription(company.ref.collection('consolidationJournalTypes'), [company]);
  const sortedConsolidationJournalTypes = sortBy(consolidationJournalTypes, 'index');
  const allConsolidationJournalTypes = [...presetConsolidationJournalTypes, ...sortedConsolidationJournalTypes];
  const consolidationJournalTypesById = keyBy(allConsolidationJournalTypes, 'id');
  const consolidationJournalTypesByName = keyBy(allConsolidationJournalTypes, 'name');
  const consolidationJournalTypeOptions = allConsolidationJournalTypes.map(_ => ({ label: _.name, value: _.id, }));
  const accountItems = useCollectionSubscription(company.ref.collection('accountItems'));
  const sortedAccountItems = sortBy(accountItems, _ => accountItemCategoryNames.indexOf(_.account_category), 'index');
  const accountItemsGroupedByConsolidationAccountItemId = groupBy(sortedAccountItems, 'consolidationAccountItemId');
  const consolidationAccountItems = sortedAccountItems.filter(_ => _.subsidiaryId === null);
  const mappedConsolidationAccountItems = consolidationAccountItems.filter(_ => accountItemsGroupedByConsolidationAccountItemId[_.id]);
  const allConsolidationAccountItems = [...mappedConsolidationAccountItems, ...presetConsolidationAccountItems];
  const consolidationAccountItemsById = keyBy(allConsolidationAccountItems, 'id');
  const consolidationAccountItemsByCode = keyBy(allConsolidationAccountItems, 'shortcut_num');
  const consolidationAccountItemsByName = keyBy(allConsolidationAccountItems, 'name');
  const consolidationAccountItemOptions = allConsolidationAccountItems.map(_ => ({ label: `${_.name}${_.shortcut_num ? ` [${_.shortcut_num}]` : ''}`, value: _.id }));
  const consolidationSegmentOptions = [...allConsolidationSegments, { name: '未選択', id: '0', }].map(_ => ({ label: _.name, value: _.id }));
  const relatedCompanyOptions = (relatedCompanies || []).map(_ => ({ label: _.display_name, value: _.id }));
  const rows = generateRowGroups(sortedConsolidationJournals, relatedCompanies, multicombinations);

  let filteredRows = rows;
  let filtersContent = null;
  if(!isEmpty(queryParams.relatedCompanyIds)) {
    filteredRows = filteredRows.filter((row) => {
      return row.items?.flatMap(_ => [_.debitCompanyId, _.creditCompanyId]).filter(_ => _).every(_ => queryParams.relatedCompanyIds.includes(_));
    });
  }
  if(!isEmpty(queryParams.openings)) {
    filteredRows = filteredRows.filter(r => queryParams.openings.map(_ => openings[_].value).includes(r.isOpening));
  }
  if(!isEmpty(queryParams.inheritTypes)) {
    filteredRows = filteredRows.filter(_ => queryParams.inheritTypes.includes(_.inheritType || 'none'))
  }
  if(!isEmpty(queryParams.approvements)) {
    filteredRows = filteredRows.filter(r => queryParams.approvements.map(_ => approvements[_].value).includes(r.approvedAt != null));
  }
  if(!isEmpty(typesForFilter)) {
    filteredRows = filteredRows.filter(_ => typesForFilter.includes(_.type))
  }
  if(!isEmpty(consolidationAccountItemIds)) {
    filteredRows = filteredRows.filter(_ => _.items.some(i => ['debit', 'credit'].some(_ => consolidationAccountItemIds.includes(i[`${_}ItemId`]))));
  }
  if(!isEmpty(consolidationSegmentIds)) {
    filteredRows = filteredRows.filter(_ => _.items.some(i => ['debit', 'credit'].some(_ => i[`${_}ItemId`] != null && consolidationSegmentIds.includes(i[`${_}SegmentId`] || '0'))));
  }
  if(!isEmpty(queryParams.intersegments)) {
    filteredRows = filteredRows.filter(_ => _.items.some(i => ['debit', 'credit'].some(_ => i[`${_}ItemId`] != null && queryParams.intersegments.map(_ => intersegments[_].value).includes(i[`${_}IsIntersegment`] || false))));
  }
  if(onlyFilteredRows === '1') {
    const onClickClearFilters = _ => history.replace(location.pathname + '?' + qs.stringify(omit(queryParams, ['onlyFilteredRows'])));
    if(consolidationAccountItemIds) {
      filteredRows = filteredRows.map(_ => {
        return {
          ..._,
          items: _.items?.map(_ => ({
            ...(consolidationAccountItemIds.includes(_.debitItemId) && pick(_, ['debitCompanyId', 'debitSegmentId', 'debitItemId', 'debitAmount', 'debitIsIntersegment'])),
            ...(consolidationAccountItemIds.includes(_.creditItemId) && pick(_, ['creditCompanyId', 'creditSegmentId', 'creditItemId', 'creditAmount', 'creditIsIntersegment'])),
          })),
        };
      });
    }
    if(consolidationSegmentIds) {
      filteredRows = filteredRows.map(_ => {
        return {
          ..._,
          items: _.items?.map(_ => ({
            ...(consolidationSegmentIds.includes(_.debitSegmentId) && pick(_, ['debitCompanyId', 'debitSegmentId', 'debitItemId', 'debitAmount', 'debitIsIntersegment'])),
            ...(consolidationSegmentIds.includes(_.creditSegmentId) && pick(_, ['creditCompanyId', 'creditSegmentId', 'creditItemId', 'creditAmount', 'creditIsIntersegment'])),
          })),
        };
      });
    }
    if(queryParams.onlyIntersegment === '1') {
      filteredRows = filteredRows.map(_ => {
        return {
          ..._,
          items: _.items?.map(_ => ({
            ...(_.debitIsIntersegment && pick(_, ['debitCompanyId', 'debitSegmentId', 'debitItemId', 'debitAmount', 'debitIsIntersegment'])),
            ...(_.creditIsIntersegment && pick(_, ['creditCompanyId', 'creditSegmentId', 'creditItemId', 'creditAmount', 'creditIsIntersegment'])),
          })),
        };
      });
    }
    filtersContent = (
      <div className="alert alert-info d-flex align-items-center gap-2">
        絞り込んだ{[(consolidationAccountItemIds && '連結科目'), (consolidationSegmentIds && '連結セグメント')].filter(_ => _).join('、')}のみ表示中。
        <Button outline color="link" className="p-0 text-secondary" onClick={onClickClearFilters}>
          <span className="fas fa-times mr-1" />
          クリア
        </Button>
      </div>
    );
  }
  const processRows = (rows) => {
    return rows.reduce((x, y) => {
      return isEmpty(y.delimiter) ? [...x.slice(0, x.length - 1), [...last(x), y]] : [...x, [y]];
    }, [])
      .map((group, i) => {
        const [{ type, isOpening, inheritType = 'なし', description, }] = group;
        return {
          type: consolidationJournalTypesByName[type]?.id || null,
          isOpening: isOpening?.toLowerCase() === 'true',
          inheritType: inheritTypesByLabel[inheritType],
          items: group.map(({ debitCompanyName, debitSegmentName, debitAccountItemCode, debitAccountItemName, debitChangeType, debitAmount, debitIsIntersegment, creditCompanyName, creditSegmentName, creditAccountItemCode, creditAccountItemName, creditChangeType, creditAmount, creditIsIntersegment, }) => {
            return {
              debitCompanyId: companiesByName[debitCompanyName]?.id || null,
              debitSegmentId: consolidationSegmentsByName[debitSegmentName]?.id || null,
              debitItemId: (company.externalType === 'bugyo' ? consolidationAccountItemsByCode[debitAccountItemCode]?.id : consolidationAccountItemsByName[debitAccountItemName]?.id) || null,
              debitChangeType: changeTypesByLabel[debitChangeType] || null,
              debitAmount: numeral(debitAmount).value(),
              debitIsIntersegment: debitIsIntersegment?.toLowerCase() === 'true',
              creditCompanyId: companiesByName[creditCompanyName]?.id || null,
              creditSegmentId: consolidationSegmentsByName[creditSegmentName]?.id || null,
              creditItemId: (company.externalType === 'bugyo' ? consolidationAccountItemsByCode[creditAccountItemCode]?.id : consolidationAccountItemsByName[creditAccountItemName]?.id) || null,
              creditChangeType: changeTypesByLabel[creditChangeType] || null,
              creditAmount: numeral(creditAmount).value(),
              creditIsIntersegment: creditIsIntersegment?.toLowerCase() === 'true',
            };
          }),
          description,
          index: i,
        };
      });
  };
  const validateRow = (row) => {
    const errors = entries(fields({ allConsolidationJournalTypes })).map(([fieldName, { validations = {} }]) => {
      return entries(validations)
        .filter(([k, v]) => !v(row[fieldName], row, k))
        .map(([key]) => `[${fieldName}] ` + get(texts.validations.general, key));
    }).flat();
    const itemErrors = row.items.flatMap((item) => {
      return entries(itemFields({ company, companies: relatedCompanies, consolidationAccountItems, consolidationSegments, })).map(([fieldName, { validations = {} }]) => {
        return entries(validations)
          .filter(([k, v]) => !v(item[fieldName], item, k))
          .map(([key]) => `[${fieldName}] ` + get(texts.validations.general, key));
      }).flat();
    });
    const totalDebitAmount = round(sumBy(row.items, 'debitAmount'), 6);
    const totalCreditAmount = round(sumBy(row.items, 'creditAmount'), 6);
    const isMatchedDebitAndCredit = totalDebitAmount === totalCreditAmount;
    const isOverCompanies = uniq(row.items.flatMap(_ => [_.debitCompanyId, _.creditCompanyId]).filter(_ => _)).length > 2;
    const isAllBlankCompanies = row.items.flatMap(_ => [_.debitCompanyId, _.creditCompanyId]).every(_ => _ == null);
    row.errors = [
      ...(isMatchedDebitAndCredit ? [] : ['貸借一致していません']),
      ...(isOverCompanies ? ['3社以上選択されています'] : []),
      ...(isAllBlankCompanies ? ['会社を1つは選択してください'] : []),
      ...errors,
      ...itemErrors,
    ];
    return row;
  };
  const processRow = (batch, row, i, importRef) => {
    const ref = consolidationJournalsRef.doc();
    batch.set(ref, {
      ...row,
      createdAt: new Date(),
      period,
      yearMonth,
      importId: importRef.id,
    }, { merge: true });
  };
  const onSubmitBatchClone = async (values, { onClickClose }) => {
    if(!window.confirm('本当にコピーしますか？')) return;

    const { yearMonth: sourceYearMonth, types } = values;
    const sources = await getCollectionData(consolidationJournalsRef.where('yearMonth', '==', sourceYearMonth));
    await batch(db, sources.filter(_ => types.includes(_.type)), (batch, source, i) => {
      batch.set(consolidationJournalsRef.doc(), {
        ...omit(source, ['id', 'ref', 'linkId', 'approvedAt', 'approvedBy', 'isUpdatedAfterCopy']),
        period,
        yearMonth,
        copiedAt: new Date(),
        sourceYearMonth,
        sourceId: source.id,
        createdAt: new Date(),
        index: source.index ?? ((last(rows)?.index || 0) + i),
        description: (source.description || ''),
      })
    });
    toast.success('コピーしました');
    onClickClose();
  };
  const onSubmitCloneToOther = async (values, { onClickClose }) => {
    if(!window.confirm(`${selectedIds.length}件を他の年月にコピーします。よろしいですか？`)) return;

    const { period: toPeriod, yearMonth: toYearMonth, } = values;
    await batch(db, selectedIds, (batch, sourceId, i) => {
      const source = consolidationJournalsById[sourceId];
      batch.set(consolidationJournalsRef.doc(), {
        ...omit(source, ['id', 'ref', 'isUpdatedAfterCopy']),
        period: toPeriod,
        copiedAt: new Date(),
        sourceYearMonth: yearMonth,
        sourceId,
        yearMonth: toYearMonth,
        createdAt: addSeconds(new Date(), i),
        index: 1000 + i,
        description: (source.description || ''),
      })
    });
    toast.success('コピーしました');
    onClickClose();
    setSelectedIds([]);
  };
  const onClickInheritFromPrevEnd = async () => {
    if(!window.confirm('前期末から開始仕訳を引き継ぎます。よろしいですか？')) return;

    try {
      const prevEndYearMonthConsolidationJournals = await getCollectionData(consolidationJournalsRef.where('yearMonth', '==', prevEndYearMonth));
      const items = prevEndYearMonthConsolidationJournals.filter(_ => _.items?.length > 0).flatMap((consolidationJournal) => {
        const { inheritType, items, } = consolidationJournal;
        const source = omit(consolidationJournal, ['id', 'ref', 'linkId', 'approvedAt', 'approvedBy', 'isUpdatedAfterCopy']);
        return ({
          none: [],
          carry: [
            {
              ...source,
              isOpening: true,
              items: items?.map((item) => {
                return {
                  ...item,
                  debitItemId: accountItemIdWithInheritBenefitId(item.debitItemId),
                  creditItemId: accountItemIdWithInheritBenefitId(item.creditItemId),
                };
              }),
            },
          ],
          reversal: [
            {
              ...source,
              inheritType: 'none',
              isOpening: true,
              items: items?.map((item) => {
                return {
                  ...item,
                  debitItemId: accountItemIdWithInheritBenefitId(item.debitItemId),
                  creditItemId: accountItemIdWithInheritBenefitId(item.creditItemId),
                };
              }),
            },
            {
              ...source,
              inheritType: 'none',
              isOpening: false,
              items: items?.map((item) => {
                return ['CompanyId', 'ItemId', 'SegmentId', 'Amount', 'IsIntersegment', 'ChangeType'].reduce((x, y) => {
                  return { ...x, [`debit${y}`]: item[`credit${y}`], [`credit${y}`]: item[`debit${y}`], };
                }, {});
              }),
            },
          ],
        })[inheritType];
      });
      await batch(db, items, (batch, item) => batch.set(consolidationJournalsRef.doc(), { ...item, isInherited: true, period, yearMonth, createdAt: new Date() }));
      toast.success('開始仕訳を引き継ぎました');

      function accountItemIdWithInheritBenefitId (itemId) {
        if(itemId == null) return null;

        const consolidationAccountItem = consolidationAccountItemsById[itemId];
        if(consolidationAccountItem == null) {
          throw {
            message: dedent`
              前期末において現在は存在しない連結科目を使用している仕訳が存在します。(${itemId.split('__')?.[1] || ''})
              科目マッピングが変更された可能性があります。
            `,
            isOperationError: true,
          };
        }
        const category = getCategory(consolidationAccountItem);
        return category.type === 'bs' ? itemId : consolidationAccountItems.find(_ => _.name.startsWith('繰越利益'))?.id || consolidationAccountItems.find(_ => getCategory(_).name === '繰越利益剰余金')?.id;
      }
    } catch(e) {
      console.error(e);
      if(e.isOperationError) {
        toast.error(e.message, { autoClose: false });
      } else {
        toast.error('失敗しました');
      }
    }
  };
  const onClickDeleteInheritedJournals = async () => {
    if(!window.confirm('前期末から引き継いだ開始仕訳を削除します。。よろしいですか？')) return;

    await batch(db, consolidationJournals.filter(_ => _.isInherited), (batch, _) => batch.delete(_.ref));
    toast.success('前期末から引き継いだ開始仕訳を削除しました。');
  };
  const onClickSortDefault = async () => {
    if(!window.confirm('種別・会社で並び替えます。よろしいですか？')) return;

    const consolidationJournalsGroupedByType = groupBy(consolidationJournals, 'type');
    const sortedConsolidationJournals = allConsolidationJournalTypes.flatMap((consolidationJournalType) => {
      return multicombinations(relatedCompanies, 2).flatMap((companies) => {
        const consolidationJournals = (consolidationJournalsGroupedByType[consolidationJournalType.id] || []).filter((consolidationJournal) => {
          const { items, } = consolidationJournal;
          return isEqual(sortBy(uniq(companies.map(_ => _.id)), _ => _), sortBy(uniq(items.flatMap(_ => [_.debitCompanyId, _.creditCompanyId]).filter(_ => _)), _ => _));
        });
        return orderBy(consolidationJournals, 'index');
      });
    });
    await batch(db, sortedConsolidationJournals, (batch, _, i) => batch.update(_.ref, { index: i, }));
    toast.success('種別・会社で並び替えました。');
  };
  const onDrop = async ({ addedIndex, removedIndex }) => {
    const newIds = arrayMoveImmutable(rows, removedIndex, addedIndex).map(_ => _.id);
    await batch(db, newIds, (batch, id, index) => {
      batch.update(company.ref.collection('consolidationJournals').doc(id), { index });
    });
  };
  const { elm, focusColor, } = useScrollFocusByQuery(_ => rows.map(_ => _.id).includes(queryParams.consolidationJournalId));
  const beforeDeleteImport = async (_import) => {
    const data = await getCollectionData(consolidationJournalsRef.where('importId', '==', _import.id));
    await batch(db, data, (batch, _) => batch.delete(_.ref));
  };
  const deleteImportDisabled = (importId) => {
    return isLockedMonth || (
      company.usesApprovement && !isLoadingConsolidationJournals && consolidationJournals.filter(_ => _.importId === importId).some(_ => _.approvedAt != null)
    );
  };
  const alerts = computeAlerts(company, sortedConsolidationJournals);
  const onClickAllApprove = async (isNot = false) => {
    if(!window.confirm(`${selectedIds.length}件を承認${isNot ? '取消' : ''}します。よろしいですか？`)) return;

    await batch(db, selectedIds, (b, _) => b.update(company.ref.collection('consolidationJournals').doc(_), {
      approvedAt: isNot ? null : new Date(),
      approvedBy: isNot ? null : pick(user, ['email', 'displayName', 'id']),
    }));
    toast.success(`承認${isNot ? '取消' : ''}しました`);
  };
  const onClickBatchDelete = async () => {
    if(!window.confirm(`${selectedIds.length}件を削除します。よろしいですか？`)) return;

    await batch(db, selectedIds, (b, _) => b.delete(company.ref.collection('consolidationJournals').doc(_)));
    toast.success(`削除しました`);
    setSelectedIds([]);
  };

  return props.translate(
    <div className="company-account-item-mappings container-fluid">
      <div className="p-4 bg-white">
        <div className="d-flex justify-content-end mb-3">
          <HelpLink text="開始・連結仕訳を作成する" />
        </div>
        <div className="d-flex justify-content-center mb-3">
          <h4>開始・連結仕訳</h4>
        </div>
        <Alerts alerts={alerts} />
        {
          isSelecting ? (
            <div className="mb-1 d-flex justify-content-start align-items-center gap-1">
              <div>{selectedIds.length} 件を選択中</div>
              <ProgressButton color="danger" process={onClickBatchDelete} disabled={isLockedMonth}>
                削除
              </ProgressButton>
              <ModalButton label="他の年月にコピー" Modal={ModelFormModal} modalProps={{ values: { period, yearMonth, }, fields: omit(batchCloneFields({ periodOptions, company, fiscalYearOfPeriod, allConsolidationJournalTypes, }), ['types']), title: '他の年月にコピー', onSubmit: onSubmitCloneToOther, submitLabel: '他の年月にコピー', }} data-operation-type="write" />
              {
                company.usesApprovement && canApprove && (
                  <Fragment>
                    <ProgressButton process={onClickAllApprove.bind(null, false)} color="success" disabled={isLockedMonth}>
                      <span className={`fas fa-check mr-1`} />
                      承認
                    </ProgressButton>
                    <ProgressButton process={onClickAllApprove.bind(null, true)} color="warning" disabled={isLockedMonth}>
                      <span className={`fas fa-times mr-1`} />
                      承認取消
                    </ProgressButton>
                  </Fragment>
                )
              }
            </div>
          ) : (
            <div>
              <div className="mb-1 d-flex justify-content-start align-items-end gap-1">
                <QuerySelector simple paramName="relatedCompanyIds" width={200} isMulti options={relatedCompanyOptions} label="会社" />
                <QuerySelector simple width={200} paramName="openings" isMulti options={openingOptions} label="開始仕訳で絞込み" disabled={onlyFilteredRows === '1'} />
                <QuerySelector simple width={200} paramName="inheritTypes" isMulti options={inheritTypeOptions} label="翌期への引き継ぎで絞込み" disabled={onlyFilteredRows === '1'} />
                {company.usesApprovement && <QuerySelector simple width={200} paramName="approvements" isMulti options={approvementOptions} label="仕訳承認で絞込み" disabled={onlyFilteredRows === '1'} />}
                <QuerySelector simple width={200} paramName="types" isMulti options={consolidationJournalTypeOptions} label="仕訳種別で絞込み" disabled={onlyFilteredRows === '1'} />
                <QuerySelector simple width={200} paramName="consolidationAccountItemIds" isMulti options={consolidationAccountItemOptions} label="連結科目で絞込み" disabled={onlyFilteredRows === '1'} />
                <QuerySelector simple width={200} paramName="consolidationSegmentIds" isMulti options={consolidationSegmentOptions} label="連結セグメントで絞込み" disabled={onlyFilteredRows === '1'} />
                <QuerySelector simple width={200} paramName="intersegments" isMulti options={intersegmentOptions} label="セグメント間で絞込み" disabled={onlyFilteredRows === '1'} />
              </div>
              <div className="mb-1 d-flex justify-content-end align-items-end gap-1">
                {
                  consolidationJournals.some(_ => _.isInherited) ? (
                    <ProgressButton color="danger" icon={<span className="fas fa-trash" />} process={onClickDeleteInheritedJournals} disabled={isLockedMonth}>
                      前期末から引き継いだ開始仕訳を削除
                    </ProgressButton>
                  ) : (
                    <ProgressButton icon={<span className="fas fa-dolly" />} process={onClickInheritFromPrevEnd} disabled={isLockedMonth}>
                      前期末から開始仕訳を引き継ぐ
                    </ProgressButton>
                  )
                }
                <ProgressButton icon={<span className="fas fa-sort-amount-down-alt" />} process={onClickSortDefault}>
                  種別・会社で並び替える
                </ProgressButton>
                <ModalButton label={<span><span className="fas fa-copy mr-1" />他の年月からコピー</span>} Modal={ModelFormModal} modalProps={{ values: { period, yearMonth, }, fields: batchCloneFields({ periodOptions, company, fiscalYearOfPeriod, allConsolidationJournalTypes, }), title: '他の年月からコピー', onSubmit: onSubmitBatchClone, submitLabel: 'コピー', }} disabled={isLockedMonth} />
                <ImportButton processRows={processRows} processRow={processRow} documentName="consolidationJournal" disabled={isLockedMonth} validateRow={validateRow} beforeDeleteImport={beforeDeleteImport} importKey={`consolidationJournals__${yearMonth}`} deleteImportConfirmMessage="インポートされた仕訳も削除されます。よろしいですか？" deleteImportDisabled={deleteImportDisabled} />
                <ExportButton fileName="連結仕訳.csv" rows={rowsForExport(company, sortedConsolidationJournals, relatedCompanies, multicombinations, allConsolidationSegments, allConsolidationAccountItems, consolidationJournalTypesById)} />
                <AddButton itemRef={consolidationJournalsRef.doc()} processValues={_ => ({ ..._, period, yearMonth, changeTypes: uniq(_.items.flatMap(_ => [_.debitChangeType, _.creditChangeType]).filter(_ => _)), })} FormModal={ConsolidationJournalFormModal} initialValues={{ items: [{}], }} formProps={{ companies: relatedCompanies, consolidationAccountItems: allConsolidationAccountItems, consolidationSegments: allConsolidationSegments, allConsolidationJournalTypes, }} disabled={isLockedMonth} />
              </div>
              {
                !isEmpty(queryParams.relatedCompanyIds) && <div className="alert alert-info my-1">
                  選択された会社のみを含む仕訳だけを表示しています
                </div>
              }
              {filtersContent}
            </div>
          )
        }
        <div className="d-flex flex-column gap-5">
          <table className="table table-bordered sticky-table" style={{ zIndex: 0 }}>
            <thead className="thead-light text-center text-nowrap">
              <tr className={classnames({ 'd-block': isDragging })}>
                <th style={{ minWidth: 75, }}>
                  {
                    (isAllSelelected => (
                      <Input
                        type="checkbox"
                        className="position-relative m-0"
                        checked={isAllSelelected}
                        onChange={_ => isAllSelelected ? setSelectedIds([]) : setSelectedIds(filteredRows.map(_ => _.id))}
                      />
                    ))(difference(filteredRows.map(_ => _.id), selectedIds).length === 0)
                  }
                </th>
                <th style={{ minWidth: 120, }}>仕訳種別</th>
                <th style={{ minWidth: 720, }}>借方/貸方</th>
                <th style={{ minWidth: 150 }}>説明</th>
                <th style={{ minWidth: 200 }}></th>
              </tr>
            </thead>
            <Container
              dragHandleSelector={`.drag-handle`}
              onDrop={onDrop}
              onDragStart={_ => toggleDragging(true)}
              onDragEnd={_ => toggleDragging(false)}
              dropPlaceholder={{ style: { background: 'eee', } }}
              render={(ref) => (
                <tbody ref={ref} style={{ border: '2px solid #ccc', }}>
                  {
                    filteredRows.map((consolidationJournal) => {
                      const { id, ref, type, isOpening = false, inheritType = 'none', items, description, linkId, copiedAt, isUpdatedAfterCopy, approvedAt, approvedBy, } = consolidationJournal;
                      return (
                        <Draggable
                          key={id}
                          render={() => {
                            const onClickCopy = async () => {
                              if(!window.confirm('本当にコピーしますか？')) return;

                              await company.ref.collection('consolidationJournals').doc().set({
                                ...pick(consolidationJournal, ['type', 'isOpening', 'inheritType', 'description', 'items', 'changeTypes']),
                                index: consolidationJournal.index + 0.01,
                                period,
                                yearMonth,
                                copiedAt: new Date(),
                                createdAt: new Date(),
                                description: (consolidationJournal.description || '') + 'のコピー',
                              });
                              toast.success('コピーしました');
                            };
                            const onClickApprove = async () => {
                              if(!window.confirm(`本当に承認${approvedAt != null ? '取消' : ''}しますか？`)) return;

                              await ref.update({
                                approvedAt: approvedAt != null ? null : new Date(),
                                approvedBy: approvedAt != null ? null : pick(user, ['email', 'displayName', 'id']),
                              });
                              toast.success(`承認${approvedAt != null ? '取消' : ''}しました`);
                            };
                            const processValues = (values) => {
                              const _values = { ...values, changeTypes: uniq(values.items.flatMap(_ => [_.debitChangeType, _.creditChangeType]).filter(_ => _)), };
                              return copiedAt == null ? (
                                _values
                              ) : isEqual(consolidationJournal.items?.map(_ => pickBy(_, _ => _)), values.items?.map(_ => pickBy(_, _ => _))) ? (
                                _values
                              ) : { ..._values, isUpdatedAfterCopy: true, };
                            };

                            return (
                              <tr ref={queryParams.consolidationJournalId === id && elm} style={{ backgroundColor: queryParams.consolidationJournalId === id && focusColor, display: !isDragging && 'table-row', }}>
                                <td style={{ width: 75 }}>
                                  <div className="d-flex align-items-start gap-2">
                                    <div>
                                      <div className={`drag-handle text-muted cursor-pointer`}>
                                        <span className="fas fa-grip-vertical" />
                                      </div>
                                      <Input
                                        type="checkbox"
                                        className="position-relative m-0"
                                        checked={selectedIds.includes(id)}
                                        onChange={_ => (selectedIds.includes(id) ? unselectId(id) : selectId(id))}
                                      />
                                    </div>
                                    <div className="d-flex flex-column gap-1">
                                      {copiedAt != null && !isUpdatedAfterCopy && <span data-tooltip-id={`tooltip-${id}`} data-tooltip-content="コピーされてから変更がありません" className="fas fa-exclamation-triangle text-warning large" />}
                                      {isOpening && <div className="badge badge-info">開始</div>}
                                      {inheritType !== 'none' && <div className="badge badge-info">{inheritTypes[inheritType].label}</div>}
                                      <Tooltip id={`tooltip-${id}`} />
                                    </div>
                                  </div>
                                  {
                                    user.dev && (
                                      <a href={`https://console.firebase.google.com/u/0/project/freee-consolidation-staging/firestore/data/~2Fcompanies~2F${company.id}~2FconsolidationJournals~2F${id}`} target="_blank">F</a>
                                    )
                                  }
                                </td>
                                <td style={{ width: 120 }}>
                                  {consolidationJournalTypesById[type]?.name}
                                </td>
                                <td style={{ minWidth: 720 }}>
                                  <table className="table table-sm table-borderless">
                                    <tbody>
                                      {
                                        items?.map((item, i) => {
                                          const { debitCompanyId, debitSegmentId, debitItemId, debitAmount, debitIsIntersegment = false, debitChangeType, creditCompanyId, creditSegmentId, creditItemId, creditAmount, creditIsIntersegment = false, creditChangeType, } = item;
                                          const debitCompany = companiesById[debitCompanyId];
                                          const debitSegment = consolidationSegmentsById[debitSegmentId];
                                          const debitItem = consolidationAccountItemsById[debitItemId];
                                          const creditCompany = companiesById[creditCompanyId];
                                          const creditItem = consolidationAccountItemsById[creditItemId];
                                          const creditSegment = consolidationSegmentsById[creditSegmentId];
                                          return (
                                            <tr key={i}>
                                              <td style={{ width: '50%', borderRight: '1px solid #ccc', }} className="text-right">
                                                <div className="d-flex flex-column">
                                                  <div className="d-flex small gap-2">
                                                    <div>{debitCompany?.display_name}</div>
                                                    <div className="text-muted">{debitSegment?.name}</div>
                                                    {debitIsIntersegment && <div className="badge badge-secondary">セグメント間</div>}
                                                    <div className="text-muted">{changeTypes[debitChangeType]?.label}</div>
                                                  </div>
                                                  <div className="d-flex justify-content-between">
                                                    <div>{debitItem?.name}</div>
                                                    <div>
                                                      {(debitItem || debitAmount || '') && integerFormat(debitAmount)}
                                                    </div>
                                                  </div>
                                                </div>
                                              </td>
                                              <td style={{ width: '50%', }} className="text-right">
                                                <div className="d-flex flex-column">
                                                  <div className="d-flex small gap-2">
                                                    <div>{creditCompany?.display_name}</div>
                                                    <div className="text-muted">{creditSegment?.name}</div>
                                                    {creditIsIntersegment && <div className="badge badge-secondary">セグメント間</div>}
                                                    <div className="text-muted">{changeTypes[creditChangeType]?.label}</div>
                                                  </div>
                                                  <div className="d-flex justify-content-between">
                                                    <div>{creditItem?.name}</div>
                                                    <div>
                                                      {(creditItem || creditAmount || '') && integerFormat(creditAmount)}
                                                    </div>
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      }
                                    </tbody>
                                  </table>
                                </td>
                                <td>
                                  <AutoLinkText>
                                    {description}
                                  </AutoLinkText>
                                </td>
                                <td className="text-nowrap text-right">
                                  <div className="d-flex gap-1 align-items-start justify-content-end">
                                    <EditButton size="sm" processValues={processValues} itemRef={ref} FormModal={ConsolidationJournalFormModal} formProps={{ companies: relatedCompanies, consolidationAccountItems: allConsolidationAccountItems, consolidationSegments: allConsolidationSegments, allConsolidationJournalTypes, values: consolidationJournal, }} disabled={approvedAt != null || isLockedMonth || onlyFilteredRows === '1'} />
                                    <DeleteButton size="sm" item={consolidationJournal} itemRef={ref} disabled={approvedAt != null || isLockedMonth} />
                                  </div>
                                  <div className="d-flex gap-1 align-items-start justify-content-end mt-1">
                                    {
                                      company.usesApprovement && (
                                        <div className="d-flex flex-column align-items-end gap-1">
                                          {
                                            canApprove && (
                                              <ProgressButton size="sm" process={onClickApprove} color={approvedAt != null ? 'warning' : 'success'} disabled={isLockedMonth}>
                                                <span className={`fas fa-${approvedAt != null ? 'times' : 'check'} mr-1`} />
                                                承認{approvedAt != null && '取消'}
                                              </ProgressButton>
                                            )
                                          }
                                          {approvedAt && <div className="text-muted small d-flex flex-column"><span>{formatDate(approvedAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}</span><span>by {approvedBy.displayName}</span></div>}
                                        </div>
                                      )
                                    }
                                    {
                                      linkId != null && (
                                        <Button tag={Link} size="sm" to={`/companies/${company.id}/pkg/relatedCompanyBalances?${qs.stringify({ period, yearMonth, linkId })}`} target="_blank">
                                          元データ
                                          <span className="fas fa-external-link-alt ml-1" />
                                        </Button>
                                      )
                                    }
                                    <ProgressButton size="sm" process={onClickCopy} disabled={isLockedMonth || onlyFilteredRows === '1'}>
                                      <span className="fas fa-copy mr-1" />
                                      コピー
                                    </ProgressButton>
                                  </div>
                                  <div className="d-flex gap-1 align-items-start justify-content-end">
                                    {
                                      user.admin && (
                                        <ProgressButton color="link" size="sm" process={_ => consolidationJournal.ref.update({ isInherited: !consolidationJournal.isInherited })}>
                                          (inherited{consolidationJournal.isInherited ? 'を解除' : 'にする'})
                                        </ProgressButton>
                                      )
                                    }
                                  </div>
                                </td>
                              </tr>
                            );
                          }}
                        />
                      );
                    })
                  }
                </tbody>
              )}
            />
          </table>
        </div>
      </div>
    </div>
  );
});
